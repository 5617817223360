import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import {injectIntl} from "gatsby-plugin-intl"
import logoFooter from "../assets/images/logo-footer.svg"
import logoWurth from "../assets/images/wurth-logo.svg"

import Header from "./header"
import {Col, Row} from "antd";

const Layout = ({children, intl, absolute = false}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header siteTitle={intl.formatMessage({id: "title"})} absolute={absolute}/>
            <div>
                <main>{children}</main>
            </div>
            <footer className="border-top">
                <div className="text-center bg-secondary py-5">
                    <Row>
                        <Col xs={24} className="text-center">
                            <img src={logoWurth} alt="Wuerth Open 2021" className="footer-logo mb-3"/>
                            <p className="text-muted fs-6 mb-0">2021 © Würth Financial Services AG</p>
                            <p className="text-muted fs-6 mb-0">Sito web realizzato da <a href="https://anemos.swiss">Anemos Group</a></p>
                        </Col>
                    </Row>
                </div>
            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
