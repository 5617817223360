import {changeLocale, FormattedMessage, Link} from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, {useState} from "react"
import Language from "./language"
import {Row, Col, Menu, Modal, Button, Divider} from "antd";
import {MenuOutlined} from '@ant-design/icons';
import logoLight from "../assets/images/logo-light.svg"
import logo from "../assets/images/logo.svg"

const Header = ({siteTitle, absolute}) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <header className={absolute ? "position-absolute dark" : ""}>
            <div className="container">
                <Row align="middle" className="d-xl-none py-4">
                    <Col xs={8} lg={10}>
                        <Button onClick={() => setModalVisible(!modalVisible)} icon={<MenuOutlined className={absolute ? "m-0 text-white" : "m-0"}/>} type="link">
                        </Button>
                    </Col>
                    <Col xs={8} lg={4} className="text-center">
                        <Link to="/">
                            <img src={absolute ? logoLight : logo} alt="Logo Wuerth FS Open 2021"/>
                        </Link>
                    </Col>
                    <Col xs={8} lg={10}>
                    </Col>
                </Row>
                <Row align="middle" className="d-none d-xl-flex">
                    <Col sm={10} className="text-left">
                        <Menu mode="horizontal"
                              style={{background: 'transparent', border: 'none'}}>
                            <Menu.Item key="gallery" className="py-4">
                                <Link to="/gallery" className="nav-link">
                                    <FormattedMessage id='menu.gallery'/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="home" className="py-4">
                                <Link to="/prizes" className="nav-link">
                                    <FormattedMessage id='menu.prizes'/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="holes" className="py-4">
                                <Link to="/holes" className="nav-link">
                                    <FormattedMessage id='menu.holes'/>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col sm={4} className="text-center">
                        <Link to="/">
                            <img src={absolute ? logoLight : logo} alt="Logo Wuerth FS Open 2021"/>
                        </Link>
                    </Col>
                    <Col sm={10}>
                        <Menu mode="horizontal"
                              style={{background: 'transparent', border: 'none'}} className="justify-content-end">
                            <Menu.Item key="sponsor" className="py-4">
                                <Link to="/sponsor" className="nav-link"><FormattedMessage
                                    id='menu.sponsor'/></Link>
                            </Menu.Item>
                            <Menu.Item key="contact" className="py-4">
                                <Link to={"/contact"} className="nav-link">
                                    <FormattedMessage id='menu.contact'/>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                </Row>
            </div>
            <Modal
                className="menu-modal"
                visible={modalVisible}
                width={1200}
                centered
                footer={null}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}>
                <div className="text-center">
                    <Link to={"/"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Home</h3>
                    </Link>
                    <Divider/>
                    <Link to={"/gallery"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Galleria</h3>
                    </Link>
                    <Divider/>
                    <Link to={"/prizes"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Premi</h3>
                    </Link>
                    <Divider/>
                    <Link to={"/holes"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Buche</h3>
                    </Link>
                    <Divider/>
                    <Link to={"/sponsor"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Sponsor</h3>
                    </Link>
                    <Divider/>
                    <Link to={"/contact"}>
                        <h3 className="text-primary fw-bold text-uppercase my-3">Contatti</h3>
                    </Link>
                </div>
            </Modal>
        </header>)
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
